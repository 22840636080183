<template>
  <v-app-bar app flat color="#fff" height="80" class="app-header overflow-x-auto overflow-y-hidden px-4" ref="appCEC">
    <v-app-bar-nav-icon @click="changeStatusSidebar()"></v-app-bar-nav-icon>
    <div
      v-if="checkType(accountTypeID)"
      id="student-search"
      style="display: flex; justify-content: space-between; margin-top: 15px"
    >
      <v-text-field
        v-model="studentName"
        label="Student name"
        placeholder="Student name"
        outlined
        class="search-item"
        dense
      ></v-text-field>

      <v-text-field
        v-model="studentCode"
        label="Student code"
        placeholder="Student code"
        outlined
        class="search-item"
        dense
      ></v-text-field>

      <v-text-field
        v-model="studentPhone"
        label="Student's phone number"
        placeholder="Student's phone number"
        outlined
        class="search-item"
        dense
      ></v-text-field>

      <v-text-field
        v-model="studentClass"
        label="Student's class"
        placeholder="Student's class"
        outlined
        class="search-item"
        dense
      ></v-text-field>

      <v-btn @click="searchStudent()" class="search-btn" color="rgba(11, 37, 133, 1)"
        ><img src="../../assets/images/search.svg" alt=""
      /></v-btn>
    </div>
    <div v-if="$route.path == '/chat'">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/images/logoCEC.jpg" class="pa-4 pb-0" />
      </router-link>
    </div>
    <v-spacer></v-spacer>

    <div class="user-info d-flex align-center">
      <div class="text-center">
        <v-menu
          offset-y
          nudge-left="50%"
          nudge-bottom="20"
          z-index="1000"
          tile
          :close-on-content-click="false"
          v-model="openMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="getNotiList()">
              <span v-show="receivedNoti != '0'">
                <v-badge color="pink" offset-x="10" offset-y="10" dot
                  ><v-icon>notifications</v-icon></v-badge
                >
              </span>
              <span v-show="receivedNoti === '0'">
                <v-icon>notifications</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card width="500">
            <div class="px-5 py-2">
              <div class="d-flex justify-space-between align-center">
                <p class="text-h4">Notifications</p>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="readAllNoti(currentUser.userInfo.userName)"
                    >
                      <v-icon>mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span> Đánh dấu tất cả là đã đọc</span>
                </v-tooltip>
              </div>
              <div>
                <v-select
                  class="pt-4"
                  v-model="notiCat"
                  :items="
                    userType === 'Student' || userType === 'Guardian'
                      ? studentNotiType
                      : userType === 'Teacher'
                      ? teacherNotiType
                      : staffNotiType
                  "
                  solo
                  color="pink"
                  label="All"
                  required
                  :filled="true"
                  @change="getResetedNotiList()"
                ></v-select>
              </div>
            </div>
            <v-divider></v-divider>
            <v-list class="noti-box" :style="{ maxHeight: '500px', overflowY: 'scroll' }">
              <section v-if="notiList.length === 0" class="text-center">
                No new notification
              </section>
              <div v-else>
                <v-list-item
                  class="noti-row-block"
                  v-for="(item, index) in notiList"
                  :key="index"
                  @click="
                    handleNotiClick(JSON.parse(item.payload))
                    readChecked(item.isRead, item.notiID)
                  "
                  :style="{ backgroundColor: item.isRead ? 'transparent' : '#EBECFE' }"
                >
                  <v-row align="center" class="py-2">
                    <v-col cols="2" class="d-flex align-center justify-center">
                      <div class="noti-img-containter">
                        <img
                          class="noti-img"
                          :src="getIconType(item.annoucementCategory)"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex align-center">
                        <h3>{{ item.title }}</h3>
                        <p class="ml-auto">{{ item.sendTime | showNotiDateTime }}</p>
                      </div>
                      <div class="noti-box-readmore">
                        <!-- <input type="checkbox" :id="`expanded ${index}`" /> -->
                        <p class="notification-desc" :style="{ fontWeight: '550' }">
                          {{ item.context }}
                        </p>
                        <!-- <label :for="`expanded ${index}`" role="button">read more</label> -->
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </div>

      <div class="text-center">
        <v-btn
          v-if="
            (functionRole.filter(
              x =>
                (x.functionID == chatPermission && x.cModify == true) ||
                (x.functionID == chatPermission && x.cFull == true) ||
                (x.functionID == chatManagementPermission && x.cModify == true) ||
                (x.functionID == chatManagementPermission && x.cFull == true)
            ).length == 2 ||
              functionRole.filter(
                x =>
                  (x.functionID == chatPermission && x.cModify == true) ||
                  (x.functionID == chatPermission && x.cFull == true)
              ).length == 1 ||
              functionRole.filter(
                x =>
                  (x.functionID == chatManagementPermission && x.cModify == true) ||
                  (x.functionID == chatManagementPermission && x.cFull == true)
              ).length == 1) &&
            (accountTypeName == 'Admin' || accountTypeName == 'Staff')
          "
          icon
          @click="chat()"
        >
          <v-icon>mdi-message-text</v-icon>
        </v-btn>
      </div>
      <div style="overflow: hidden">
        <p
          class="fs-18 font-weight-bold mr-1"
          style="max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          Hi, {{ userFullName }}
        </p>
      </div>
      <!-- <div class="fs-18 font-weight-bold pr-3"></div> -->
      <v-avatar class="pl-2 pr-2" size="24">
        <!-- <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" /> -->
        <router-link :to="{ name: 'my-profile' }">
          <img v-if="userPhoto" :src="'data:image/jpeg;base64,' + userPhoto" />
          <i v-else class="isax isax-profile-circle fs-24"></i>
        </router-link>
      </v-avatar>
      <v-menu offset-y min-width="177" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#000" v-bind="attrs" v-on="on">arrow_drop_down</v-icon>
        </template>
        <v-list>
          <v-list-item @click="goMyProfile()">
            <v-list-item-icon>
              <i class="isax isax-profile-circle fs-24"></i>
            </v-list-item-icon>
            <v-list-item-title>My profile</v-list-item-title>
          </v-list-item>
          <!-- <v-list-group :value="false" prepend-icon="isax-language-circle">
            <template v-slot:activator>
              <v-list-item-content class="pa-0">
                <v-list-item-title>Language</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item @click="changeLanguage('en')">
              <v-list-item-title :style="language === 'vi' ? 'padding-left: 21px !important' : ''"><v-icon v-if="language === 'en'" style="color: green !important;font-size: 17px !important;padding-bottom: 4px;">mdi-check</v-icon> English </v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeLanguage('vi')">
              <v-list-item-title :style="language === 'en' ? 'padding-left: 21px !important' : ''"><v-icon v-if="language === 'vi'" style="color: green !important;font-size: 17px !important;padding-bottom: 4px;">mdi-check</v-icon> Vietnamese</v-list-item-title>
            </v-list-item>
          </v-list-group> -->
          <v-list-group
            v-if="
              centerList.length > 0 &&
              currentUser.locationPermissions &&
              currentUser.locationPermissions.length > 0 &&
              currentUser.locationPermissions[0].accountTypeName !== 'Teacher'
            "
            :value="false"
            prepend-icon="domain"
          >
            <template v-slot:activator>
              <v-list-item-content class="pa-0">
                <v-list-item-title>Choose center</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-select
              class="pl-4 pr-1"
              :items="centerList"
              item-text="location"
              item-value="locationID"
              append-icon="keyboard_arrow_down"
              prepend-icon="domain"
              dense
              outlined
              hide-details
              style="max-width: 220px"
              v-model="currentCenter"
              @change="changeCenter()"
            ></v-select>
          </v-list-group>
          <v-list-item
            v-if="
              centerList.length > 0 &&
              currentUser.locationPermissions &&
              currentUser.locationPermissions.length > 0 &&
              currentUser.locationPermissions[0].accountTypeName !== 'Teacher'
            "
            @click="goVersionList()"
          >
            <v-list-item-icon>
              <i class="isax isax-note-1 fs-24"></i>
            </v-list-item-icon>
            <v-list-item-title> Release Note </v-list-item-title>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <i class="isax isax-export fs-24"></i>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <FlashMessage></FlashMessage>
    </div>
  </v-app-bar>
</template>

<script>
import { loginadmin } from '@/api/login.js'
import { notification } from '@/api/notification.js'
import ConfigDate from '@/mixins/configDate.js'
// import  dayjs  from 'dayjs'
// import Autocomplete from '@/components/Utils/Autocomplete.vue'

export default {
  components: {},
  mixins: [ConfigDate],
  data() {
    return {
      userFullName: JSON.parse(localStorage.getItem('currentUser')).userInfo.fullName,
      userPhoto: JSON.parse(localStorage.getItem('currentUser')).userInfo.photo,
      userType: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeName,
      currentCenter: JSON.parse(localStorage.getItem('currentCenter')),
      currentUser: JSON.parse(localStorage.getItem('currentUser')),
      locationPermissions: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      functionRole: [],
      accountTypeName: '',
      notiPage: 1,
      accountTypeID: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      chatPermission: process.env.VUE_APP_ENV_FUNCTION_CHAT,
      chatManagementPermission: process.env.VUE_APP_ENV_FUNCTION_CHAT_MANAGEMENT,
      language: localStorage.getItem('systemLanguage'),
      windowWidth: window.innerWidth,

      //student search
      student: {},
      studentName: '',
      studentClass: '',
      studentPhone: '',
      studentCode: '',
      openMenu: false,
      selectedOpen: false,

      studentNotiType: JSON.parse(process.env.VUE_APP_STUDENT_NOTI_CAT),
      teacherNotiType: JSON.parse(process.env.VUE_APP_TEACHER_NOTI_CAT),
      staffNotiType: JSON.parse(process.env.VUE_APP_STAFF_NOTI_CAT),
      notiCat: '',
      notiList: [],
      reveal: false,
    }
  },
  computed: {
    centerList() {
      return JSON.parse(localStorage.getItem('currentUser')).locationPermissions
    },
    receivedNoti() {
      return this.$store.getters.getReceivedNotiNum.toString()
    },
  },
  created() {
    this.userFullName = JSON.parse(localStorage.getItem('currentUser')).userInfo.fullName
    this.functionRole =
      JSON.parse(localStorage.getItem('functionRole')) == null
        ? []
        : JSON.parse(localStorage.getItem('functionRole'))
    this.locationPermissions.forEach(x => {
      if (x.locationID == this.currentCenter) {
        this.accountTypeName = x.accountTypeName
      }
    })
    if (window.innerWidth < 1025) {
      this.$store.state.isActiveSidebar = true
    } else {
      this.$store.state.isActiveSidebar = false
    }

    if (JSON.parse(localStorage.getItem('searchStudent'))) {
      this.student = JSON.parse(localStorage.getItem('searchStudent'))
      this.studentName = this.student.studentName
      this.studentCode = this.student.studentCode
      this.studentPhone = Number(this.student.studentPhoneNum)
      this.studentClass = this.student.studentClass
    }
  },
  watch: {
    windowWidth(value) {
      if (value < 1025) {
        this.$store.state.isActiveSidebar = true
      } else {
        this.$store.state.isActiveSidebar = false
      }
    },
    studentPhone(value) {
      if (value === 0) this.studentPhone = null
    },
  },

  mounted() {
    // Set up a Broadcast Channel to listen for messages
    const channel = new BroadcastChannel('background-message-channel')

    // Handle the message received from the service worker
    channel.addEventListener('message', event => {
      // Execute your code here
      const notiNum = this.$store.getters.getReceivedNotiNum + 1
      this.$store.commit('setReceivedNotiNum', notiNum)

      // Handle the rest of your background message logic
      console.log('Background Message Payload:', event.data)
    })

    // Clean up the Broadcast Channel when the component is destroyed

    const self = this
    //listen to redirect event from background if user is student
    if (this.accountTypeID === 1) {
      navigator.serviceWorker.addEventListener('message', function (event) {
        if (event.data.action === 'navigate') {
          window.location.href = event.data.url
        }
        if (event.data.action === 'receive-noti-payload') {
          self.$store.commit('setNotiPayload', event.data.notiPayload)
        }
      })
    }

    //custom received notification
    this.$messaging.onMessage(payload => {
      const notiPayload = JSON.parse(payload.data.payload)
      this.flashMessage.show(
        {
          title: payload.notification.title,
          message: payload.notification.body,
          position: 'right top',
          icon: this.getIconType(),
          blockClass: 'custom_noti_msg_block',
          wrapperClass: 'custom_noti_msg_wrapper',
          iconClass: 'custom_noti_msg_icon',
          contentClass: 'custom_noti_msg_content',
          x: 10,
          y: -110,
        },
        {
          mounted: () => {
            //append click event on noti when user is student
            if (this.accountTypeID === 1) {
              const notiBlock = document.querySelectorAll('.custom_noti_msg_block')
              const targetNoti = notiBlock[notiBlock.length - 1]
              targetNoti.addEventListener('click', () => {
                this.handleNotiClick(notiPayload)
              })
            }
          },
          // destroyed: () => {
          // },
        }
      )
      const notiNum = this.$store.getters.getReceivedNotiNum + 1
      this.$store.commit('setReceivedNotiNum', notiNum)
    })

    //listen to scroll event
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  updated() {},

  beforeDestroy() {
    const channel = new BroadcastChannel('background-message-channel')
    channel.close()
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onClickOutside() {
      this.openMenu = false
    },
    async readChecked(isRead, id) {
      if (isRead) return
      else {
        await notification.readOneNotification({ notiID: id })
        this.getNotiList()
      }
    },
    async readAllNoti(username) {
      await notification.readAllNotification({ username: username })
      this.getNotiList()
    },
    handleNotiClick(data) {
      this.openMenu = false
      const link = Object.prototype.hasOwnProperty.call(data, 'type')
        ? this.getDynamicLink(data.type)
        : this.getDynamicLink(data.annoucementCategory)
      this.$router.push({ name: link }).catch(() => {})
      this.$store.commit('setNotiPayload', data)
    },
    scrollToTheEnd() {
      const notiBox = document.querySelector('.noti-box')

      notiBox.addEventListener('scroll', async () => {
        const scrollTop = notiBox.scrollTop
        const scrollY = notiBox.scrollHeight - notiBox.clientHeight
        const toTheEnd = Math.ceil(scrollTop) === scrollY
        if (toTheEnd) {
          const userID = this.currentUser.userInfo.userID
          const announCate = this.getAnnounCate(this.notiCat)
          this.notiPage += 1
          await notification.getNotiList(userID, this.notiPage, 10, announCate).then(res => {
            this.notiList = [...this.notiList, ...res]
          })
        }
      })
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    getDynamicLink(type) {
      switch (type) {
        case 'SCHEDULE_ABSENT':
        case 'SCHEDULE_ATTENDED':
        case 'SCHEDULE_HW_NOTDONE':
        case 'SCHEDULE_REMIND_EXAM':
        case 'LESSONREPORT_NEW':
          return 'student-calendar-overview'
        case 'EXAMRESULT_NEW':
          return 'student-test'
        case 'OTHER':
          return 'view-announcement'
        case 'MONTHLYCOMT_NEW':
        case 'MONTHLYCOMT_REMIND':
          return 'student-monthly-comment'
        default:
          return 'home'
      }
    },
    getIconType(category) {
      switch (category) {
        case 'ACHIEVEMENT':
          return require('../../assets/images/noti-achieve.svg')
        case 'ACTIVITY':
          return require('../../assets/images/noti-side-acti.svg')
        case 'LESSONREPORT':
          return require('../../assets/images/noti-lesson-rp.svg')
        case 'EXAMRESULT':
          return require('../../assets/images/noti-test.svg')
        case 'FEEDBACK':
          return require('../../assets/images/noti-chat.svg')
        case 'MONTHLYCOMT':
          return require('../../assets/images/noti-note.svg')
        case 'SCHEDULE':
          return require('../../assets/images/noti-schedule.svg')
        case 'TASK':
          return require('../../assets/images/noti-new-task.svg')
        case 'FEE':
          return require('../../assets/images/noti-fee.svg')
        case 'MYABSENT':
          return require('../../assets/images/noti-my-absent.svg')
        case 'RECORDING':
          return require('../../assets/images/noti-record.svg')
        case 'STUABSENT':
          return require('../../assets/images/noti-absent.svg')
        default:
          return require('../../assets/images/noti-bell.svg')
      }
    },
    getAnnounCate(category) {
      switch (category) {
        case 'Achievement & Rewards':
          return 'ACHIEVEMENT'
        case 'Side Activities':
          return 'ACTIVITY'
        case 'Lesson Report':
          return 'LESSONREPORT'
        case 'Test':
          return 'EXAMRESULT'
        case 'Feedback':
          return 'FEEDBACK'
        case 'Monthly Comments':
          return 'MONTHLYCOMT'
        case 'Schedule':
          return 'SCHEDULE'
        case 'New Task':
          return 'TASK'
        case 'Fee':
          return 'FEE'
        case 'My Absent':
          return 'MYABSENT'
        case 'Recording':
          return 'RECORDING'
        case 'Student Absent':
          return 'STUABSENT'
        case 'Other':
          return 'OTHER'
        default:
          return ''
      }
    },
    changeStatusSidebar() {
      this.$store.state.isActiveSidebar = !this.$store.state.isActiveSidebar
    },
    goMyProfile() {
      this.$router.push({
        name: 'my-profile',
      })
    },
    goVersionList() {
      this.$router.push({
        name: 'version-view',
      })
    },
    changeLanguage(lang) {
      localStorage.setItem('systemLanguage', lang)
      location.reload()
    },
    checkType(roleid) {
      return [3, 4, 5].includes(roleid)
    },

    logout() {
      // if (this.currentUser.userInfo.accountTypeID == 4) {
      //   localStorage.removeItem('currentCenter')
      //   localStorage.removeItem('accountTypeID')
      // }
      // localStorage.clear()
      loginadmin.logout()
      this.$router.push({ name: 'login' })
    },
    changeCenter() {
      localStorage.setItem('currentCenter', JSON.stringify(this.currentCenter))
      location.reload()
    },
    chat() {
      let routeData = this.$router.resolve({ name: 'chat' })
      window.open(routeData.href, '_blank')
    },
    searchStudent() {
      localStorage.setItem(
        'searchStudent',
        JSON.stringify({
          studentName: this.studentName,
          studentCode: this.studentCode,
          studentPhoneNum: Number(this.studentPhone),
          studentClass: this.studentClass,
        })
      )

      this.$router
        .push({
          path: '/student-search',
          name: 'student-search',
          query: {
            studentName: this.studentName,
            studentCode: this.studentCode,
            studentPhoneNum: Number(this.studentPhone),
            studentClass: this.studentClass,
          },
        })
        .catch(err => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          console.log(err)
          // But print any other errors to the console
          location.reload()
        })
    },
    async getNotiList() {
      this.$store.commit('setReceivedNotiNum', 0)
      const userID = this.currentUser.userInfo.userID
      const announCate = this.getAnnounCate(this.notiCat)
      await notification.getNotiList(userID, 1, 10, announCate).then(res => {
        this.notiList = res
        this.scrollToTheEnd()
      })
    },
    getResetedNotiList() {
      this.notiList = []
      this.notiPage = 1
      this.getNotiList()
    },
  },
}
</script>
<style lang="scss">
header {
  &.v-sheet {
    border-bottom: 2px solid #ececf6;
    border-color: #ececf6 !important;
  }

  #student-search {
    .search-item {
      max-height: 55px;
      width: 180px;
      margin-right: 10px;
    }
    .search-btn {
      height: 34px;
      min-width: 50px;
      padding: 5px 8px;
    }
  }
}
.custom_noti_msg_block {
  max-width: 300px;
  max-height: 200px;
  min-height: 100px;
  display: flex;
  padding: 0 10px;
  .custom_noti_msg_wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    .custom_noti_msg_icon {
      margin-left: 5px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .custom_noti_msg_content {
      ._vue-flash-msg-body__title {
        font-weight: 700;
      }
      ._vue-flash-msg-body__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.noti-box-readmore {
  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .notification-desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  input:focus ~ label {
    outline: -webkit-focus-ring-color auto 5px;
  }

  input:checked + p {
    -webkit-line-clamp: unset;
  }

  input:checked ~ label {
    display: none;
  }
  label {
    color: #40c4ff;
  }
}
.noti-row-block {
  &:hover {
    background-color: #e8e8e8;
    cursor: pointer;
  }
  .noti-img-containter {
    background-color: #ebecfe;
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 50%;
    .noti-img {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
    }
  }
}
.noti-box, .app-header {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .switch {
    --active: #275efe;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  .switch {
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>
